.category-box {
    position: relative;
}


.scrollBox{
    overflow-x: auto;
    display: flex;
    scroll-snap-type: x mandatory; 
    gap: 30px;
    width: 95vw;
    flex: 0 0 auto; 
    padding: 10px;
}

.arrow-btn {
    z-index: 1;
    display: block;
    position: absolute;
    height: 70px;
    width: 70px;
    margin: 0;
    padding: 0;
    color: transparent;
    font-size: 0;
    line-height: 0;
    text-align: center;
    background: rgb(255 255 255/ 70%);
    border-width: 1px 1px;
    border-style: solid;
    border-color: #dfdfdf;
    border-radius: 50%;
    box-shadow: 0 2px 6px rgb(0 0 0 / 7%);
    outline: 0;
    transition: 0.2s ease all;
    cursor: pointer;
    flex: 0 0 auto; 
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(-50%);
    top:45%;
}

.arrow-btn-right {
    right: 10px;
}

.arrow-btn-left {
    left: 20px;
}

.arrow-btn:hover {
    background: rgba(79, 177, 243, 0.452);
    box-shadow: 0 2px 6px rgb(0 0 0 / 80%);
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: center;
}
.product-box-ctgry{
  width: 300px; /* Adjust width as needed */
  height: 300px;
  /* background-image: url("../public/images/product1.png"); */
  background-size: cover; /* Ensure the image fits within the element */
  background-repeat: no-repeat; /* Prevent repeating the image */
  background-position: center; /* Center the image within the element */
  position: relative;
  border-radius: 5px;
  flex: 0 0 auto; 
  scroll-snap-align: start; /* Optional: For smooth snapping to cards */
  user-select: none;
  cursor: pointer;
  border-radius: 30px;
}

.product-box-ctgry:hover{
  box-shadow: 0px 0px 5px 5px lightslategray;
}

.scrollBox::-webkit-scrollbar {
    height: 1px;
  }
  
  .scrollBox::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  .scrollBox::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .all-category {
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: relative;
  }

  .sub-list-product {
    width: 250px; /* Adjust width as needed */
    /* background-image: url("../public/images/product1.png"); */
    background-size: cover; /* Ensure the image fits within the element */
    background-repeat: no-repeat; /* Prevent repeating the image */
    background-position: center; /* Center the image within the element */
    position: relative;
    border-radius: 5px;
  }

  .sub-list-product-height {
    height: 250px;
  }
  
  .sub-list-product-title {
    color: white;
    background-color: rgb(98 96 96 / 80%);
    padding: 6px 20px;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
    position: absolute;
    bottom: 10px;
    font-weight: bold;
    letter-spacing: 2px;
  }

  .sub-list-services {
    letter-spacing: 1px;
    text-align: center;
    font-weight: 900;
    padding-top: 5rem;
  }

  .sub-list-product-box{
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
    margin: 10px;
    justify-content: center;
    background-image: url('../../public/images/Rectangle2.png');
    background-size: cover;
    background-repeat: repeat;
  }

  .sub-lis-specifications{
    padding: 0.5rem;
    line-height: 1.5;
  }