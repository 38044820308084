.header-box{
    background-color: rgb(98 96 96 / 80%);
    position: fixed;
    top:0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    z-index: 1;
    width: 100vw;
    align-items: center;
}

.header-box-mobile{
    background-image: url('../../public/images/abstract-grey.jpg');
    position: fixed;
    top:0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-size: cover;
    right: 0;
    
    z-index: 1;
    width: 100vw;
    align-items: center;
}
.header-logo {
    background-image: url('../../public/images/logodp.jpg');
    background-size: cover;
    border-radius: 50%;
    margin-left: 10%;
    width: 50px;
    height: 50px;
    position: relative;
    z-index: 1;
}

.header-logo-mobile {
    background-image: url('../../public/images/logodp.jpg');
    background-size: cover;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: relative;
    margin: 8px;
    z-index: 1;
}



.header-box > ul{
    display: flex;
    margin-right: 10%;
    align-items: center;
}
.header-box > ul> li {
    
    font-weight: 400;
    padding: 10px 30px;
    list-style: none;
}

.header-box > ul> li > a{
    text-decoration: none !important;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 5px;
}

.ctgry-header-name{
    width: 150px;
    right: 100px;
}

.whatsapp{
    width: 20px;
}