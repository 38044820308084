* {
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
}

.Banner{
  background-image: url("../public/images/banner.png");
  width: 100vw;
  background-size: cover;
  height: 40vw;
  background-position: left bottom;
  background-repeat: no-repeat;
  position: relative;
}

.name{
  background-image: url('../public/images/name.png');
  position: absolute;
  top: 50%;
  transform: translatey(-50%);
  right: 30px;
  width: 60%;
  height: 100px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat
  ;
}
.services{
  letter-spacing: 1px;
  text-align: center;
  font-weight: 900;
}

.product-box{
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin: 10px;
  justify-content: center;
  background-image: url('../public/images/Rectangle2.png');
  background-size: contain;
  background-repeat: repeat;
}
.product {
  width: 250px; /* Adjust width as needed */
  height: 250px;
  cursor: pointer;
  background-size: cover; /* Ensure the image fits within the element */
  background-repeat: no-repeat; /* Prevent repeating the image */
  background-position: center; /* Center the image within the element */
  position: relative;
  border-radius: 5px;
}

.product:hover{
  box-shadow: 0px 0px 5px 5px lightslategray;
}

.product-title {
  color: white;
  background-color: rgb(98 96 96 / 80%);
  padding: 6px 20px;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 10px;
  font-weight: bold;
  letter-spacing: 2px;
}

.phrase {
  background-image: url('../public/images/phrase.png');
  position: absolute;
  top: 65%;
  transform: translatey(-50%);
  right: 10%;
  width: 30%;
  height: 30px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat
}

.whatsappFixed{
  width: 50px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  box-shadow: 0px 0px 5px 5px lightslategray;
  border-radius: 50%;
}