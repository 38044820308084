.follow{

}

.Address-Box{
    display: flex;
    width: 100vw;
    justify-content: space-around;
    align-items: center;
    background-image: url('../../public/images/FooterBG.png');
    padding: 1rem;
    box-sizing: border-box;
    flex-wrap: wrap;
}
.Address-Box img{
    /* font-size: 12px; */
    height: 1.2rem;
    width: 1.2rem;
}

.Address > div{
    margin: 1rem;
    display: flex;
    align-items: center;
}

.Address > div > span {
    margin-left: 10px;
}
.Address > div > span > a, .follow > div > a {
    text-decoration: none;
    color: black;
}

.Address > div > a {
    text-decoration: none;
    color: black;
}

.socialMedia{
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    margin: 5px;
}