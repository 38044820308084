.App{
    position: relative;
}
.about-title {
    text-align: center;
    font-weight: 900;
    padding: 1rem;
    box-sizing: border-box;
    margin-bottom: 0;
}

.about-img {
    background-image: url("../../public/images/about.png");
    width: 300px; /* Adjust width as needed */
    height: 300px;
    background-size: cover; /* Ensure the image fits within the element */
    background-repeat: no-repeat; /* Prevent repeating the image */
    background-position: center;
}

.about-content{
    width: 50%;
    max-width: 600px;
    font-size: 18px;
    min-width: 270px;
    margin: 0;
    letter-spacing: 2px;
}
.about-box {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 10px;
}

.About{
    background-image: url('../../public/images/bg2.png');
    background-size: contain;
    background-repeat: repeat;
}